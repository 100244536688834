import React from "react";
import styled from "styled-components";

import { respondTo } from "theme/utils/_respondTo";

import Image from "components/atoms/Image";

const FrontImageStyle = styled.div`
  max-width: 900px;
  margin: 0 auto;

  ${respondTo.tland`
    max-width: 85%;
  `};
`;

const FrontImage = (props) => {
  return (
    <FrontImageStyle>
      <Image src={props.url} alt={props.alt} />
    </FrontImageStyle>
  );
};

export default FrontImage;
