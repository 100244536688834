const profile = {};

profile.footerNavi = [
  {
    title: "Impressum",
    path: "/impressum",
  },
  {
    title: "Datenschutz",
    path: "/datenschutz",
  },
];

export default profile;
