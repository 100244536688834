import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { LANDINGPAGE } from "gql/common";

import FrontFAQStyle from "./index.styles";

import Heading from "components/atoms/Heading";
import Paragraph from "components/atoms/Paragraph";

const FrontFAQ = () => {
  const { data, loading, error } = useQuery(LANDINGPAGE, {
    variables: { id: 3 },
  });

  let indexPlus;

  const [active, setActive] = useState(0);

  const eventHandler = (e, index) => {
    e.preventDefault();
    setActive(index);
  };

  const indexCount = (index) => {
    indexPlus = index + 1;
    return indexPlus;
  };

  const getAnswers = (array) => {
    return array.map((item, index) => {
      return (
        <Paragraph
          key={index}
          dangerouslySetInnerHTML={{ __html: item }}
          className="util-paragraph-reduced"
        />
      );
    });
  };

  return (
    <FrontFAQStyle>
      <form>
        {data.landingpage.contents.map((content, index) => {
          let acc_section = "";
          if (content.__typename === "FaqItem") {
            acc_section = content.contents.map((tab, index) => (
              <div key={index} className="faq-box">
                <div
                  onClick={(e) => eventHandler(e, index)}
                  className={
                    active === index
                      ? "faq-box__question_box active"
                      : "faq-box__question_box inactive"
                  }
                  aria-expanded={active === index ? "true" : "false"}
                  aria-controls={"sect-" + indexCount(index)}
                  aria-disabled={active === index ? "true" : "false"}
                  tabIndex={indexCount(index)}
                >
                  <Heading level={4} color="white">
                    <span className="title-wrapper">
                      {tab.question}
                      <span
                        className={
                          active === index
                            ? "title-icon plus"
                            : "title-icon minus"
                        }
                      ></span>
                    </span>
                  </Heading>
                </div>
                <div
                  id={"sect-" + indexCount(index)}
                  className={
                    active === index
                      ? "faq-box__answer_box panel-open"
                      : "faq-box__answer_box panel-close"
                  }
                >
                  {getAnswers(tab.answer)}
                </div>
              </div>
            ));
          }
          return acc_section;
        })}
      </form>
    </FrontFAQStyle>
  );
};

export default FrontFAQ;
