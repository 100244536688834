import React from "react";
import styled from "styled-components";

import { respondTo } from "theme/utils/_respondTo";
import theme from "theme/theme";

const FooterBlockStyle = styled.div`
  margin: 0;
  padding: 0;
  color: ${theme.colorWhite};

  :not(:last-child) {
    border-right: solid 1px ${theme.colorGrey2};
    padding-right: 4rem;
  }

  ${respondTo.tport`
    padding-right: 0 !important;
    border-right: none !important;

    :not(:last-child) {
      border-bottom: solid 1px ${theme.colorGrey2};
      margin: 2rem auto;
      padding-bottom: 2rem;
    }

  `}

  p, a {
    font-size: 1.4rem;
    line-height: 2.8rem;
    margin: 0 0 1.5rem !important;
    color: ${theme.colorWhite};
  }

  h4 {
    color: ${theme.colorWhite};
  }
`;

const FooterBlock = (props) => {
  return <FooterBlockStyle>{props.children}</FooterBlockStyle>;
};

export default FooterBlock;
