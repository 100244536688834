import styled from "styled-components";

const LayoutStyle = styled.div`
  .header {
    width: 100%;
    position: absolute;
  }

  .page {
    margin: 0 auto;
    padding: 14rem 0 7rem;
  }

  .page__home {
    margin: 0 auto;
    padding: 0 0 7rem;
  }
`;

export default LayoutStyle;
