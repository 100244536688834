import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

import theme from "theme/theme";
import { respondTo } from "theme/utils/_respondTo";

import Paragraph from "components/atoms/Paragraph";

const FrontVideoStyle = styled.div`
  p {
    color: ${theme.colorWhite};
  }
  .embedded-video {
    margin: 0 auto;
    max-width: 100%;
    width: 74rem !important;
    height: 40.5rem !important;

    ${respondTo.tport`
      width: 64rem !important;
      height: 36rem !important;
    `}

    ${respondTo.phone`
      width: 48rem !important;
      height: 27rem !important;
    `}
  }
`;

const FrontVideo = (props) => {
  return (
    <FrontVideoStyle>
      <Paragraph
        dangerouslySetInnerHTML={{ __html: props.description }}
        className="util-margin-bottom-large"
      />
      <ReactPlayer
        url={props.link}
        className="embedded-video util-margin-bottom-medium"
        controls={true}
      />
    </FrontVideoStyle>
  );
};

export default FrontVideo;
