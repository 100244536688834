import PropTypes from "prop-types";
import styled from "styled-components";

import theme from "theme/theme";

const ContentBlock = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  margin: 0;
  padding: 8rem 0;
  background-color: ${(props) =>
    props.background ? props.background : theme.colorWhite};

  width: 100%;
  max-width: 100%;
  }
`;

ContentBlock.propTypes = {
  background: PropTypes.string,
};

export default ContentBlock;
