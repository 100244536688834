import PropTypes from "prop-types";
import styled from "styled-components";

import { respondTo } from "theme/utils/_respondTo";
import theme from "theme/theme";

const Paragraph = styled.p`
  font-family: ${theme.fontBodyText};
  font-size: ${theme.defaultFontSize};
  line-height: ${theme.defaultLineHeight};
  font-weight: ${(props) => (props.bold ? "bold" : "regular")};
  font-style: ${(props) => (props.em ? "italic" : "regular")};
  color: ${(props) => (props.reverse ? theme.colorGrey2 : props.color)};

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  ${respondTo.tport`
      max-width: 80%;
    `}

  ${respondTo.phone`
      max-width: 100%;
    `}
`;

Paragraph.propTypes = {
  color: PropTypes.string,
  reverse: PropTypes.bool,
  bold: PropTypes.bool,
  em: PropTypes.bool,
};

Paragraph.defaultProps = {
  color: theme.colorGrey6,
};

export default Paragraph;
