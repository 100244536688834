import React from "react";
import styled from "styled-components";
import { positionAbsoluteCenter } from "theme/utils/_mixins";

import { respondTo } from "theme/utils/_respondTo";
import theme from "theme/theme";

import Heading from "components/atoms/Heading";
import HeaderImage from "theme/images/DocuClean_Logo_negativ.svg";

const FrontHeaderImageStyle = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  background-image: url(${(props) => (props.img_src ? props.img_src : "")});
  background-size: cover;
  background-position: top;

  .header__title {
    z-index: 100;
    text-indent: 5;

    ${positionAbsoluteCenter}
  }

  img {
    ${respondTo.tport`
      max-width: 80vw;
    `}

    ${respondTo.phone`
      max-width: 60vw;
    `}
  }
`;

const FrontHeaderImage = (props) => {
  return (
    <FrontHeaderImageStyle img_src={props.img_src}>
      <div className="header__title">
        <img
          src={HeaderImage}
          height="80"
          alt={props.title}
          className="util-margin-top-large util-margin-bottom-small"
        />
        <Heading level={1} color={theme.colorWhite}>
          {props.claim}
        </Heading>
      </div>
    </FrontHeaderImageStyle>
  );
};

export default FrontHeaderImage;
