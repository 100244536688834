export function clearfix() {
  return `
    &::after {
      content: "";
      display: table;
      clear: both;
    }
  `;
}

export function positionAbsoluteCenter() {
  return `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;
}
