import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { LANDINGPAGE } from "gql/common";

import theme from "theme/theme";

import HeaderImage from "theme/images/header-image-docuclean-rohtexte-strukturieren.jpg";
import FrontImageSrc from "theme/images/DocuClean_Web-Oberflaech-Voransicht.jpg";

import ContentBlock from "components/atoms/ContentBlock";
import ContentGrid from "components/molecules/ContentGrid";
import ContentRow from "components/molecules/ContentRow";
import ContentTitle from "components/molecules/ContentTitle";

import FrontCodeBlock from "components/molecules/FrontCodeBlock";
import FrontCTA from "components/molecules/FrontCTA";
import FrontFAQ from "components/molecules/FrontFAQ";
import FrontFeature from "components/molecules/FrontFeature";
import FrontHeaderImage from "components/molecules/FrontHeaderImage";
import FrontImage from "components/molecules/FrontImage";
import FrontLicense from "components/molecules/FrontLicense";
import FrontParagraph from "components/molecules/FrontParagraph";
import FrontUSP from "components/molecules/FrontUSP";
import FrontValueProposition from "components/molecules/FrontValueProposition";
import FrontVideo from "components/molecules/FrontVideo";
import Spinner from "components/atoms/Spinner";

const HomePage = () => {
  const { data, loading, error } = useQuery(LANDINGPAGE, {
    variables: { id: 3 },
  });

  return (
    <div className="page__home">
      {loading ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <FrontHeaderImage
            title={data.landingpage.title ? data.landingpage.title : "No Title"}
            claim={data.landingpage.claim ? data.landingpage.claim : "No Claim"}
            img_src={HeaderImage}
            img_alt={data.landingpage.image.alt}
          />
          <ContentBlock>
            <ContentRow>
              <FrontUSP data={data.landingpage.usp} />
              {data.landingpage.contents.map((content, index) => {
                let front_image = "";
                if (content.__typename === "ImageItem") {
                  front_image = (
                    <FrontImage
                      key={content.id}
                      url={FrontImageSrc}
                      alt={content.alt}
                    />
                  );
                }
                return front_image;
              })}
            </ContentRow>
          </ContentBlock>
          {data.landingpage.contents.map((content, index) => {
            let home = "";
            if (content.__typename === "ValuePropositionItem") {
              return (
                <ContentBlock key={content.id}>
                  <ContentRow>
                    <ContentTitle
                      title={content.title}
                      color={theme.colorGrey6}
                    />
                    <ContentGrid>
                      {content.contents.map((item, index) => {
                        return (
                          <FrontValueProposition
                            key={item.id}
                            title={item.title}
                            icon={item.icon}
                            description={item.description}
                          />
                        );
                      })}
                    </ContentGrid>
                  </ContentRow>
                </ContentBlock>
              );
            } else if (content.__typename === "ParagraphsItem") {
              return (
                <ContentBlock key={content.id} background={theme.colorGrey2}>
                  <ContentRow>
                    {content.text.map((item, index) => {
                      return <FrontParagraph key={index} text={item} />;
                    })}
                  </ContentRow>
                </ContentBlock>
              );
            } else if (content.__typename === "VideoItem") {
              return (
                <ContentBlock key={content.id} background={theme.colorPrimary}>
                  <ContentRow>
                    <ContentTitle
                      title={content.title}
                      color={theme.colorWhite}
                    />
                    <FrontVideo
                      description={content.description}
                      link={content.link}
                    />
                  </ContentRow>
                </ContentBlock>
              );
            } else if (content.__typename === "FeatureItem") {
              return (
                <ContentBlock key={content.id}>
                  <ContentRow>
                    <ContentTitle
                      title={content.title}
                      color={theme.colorGrey6}
                    />
                    <ContentGrid>
                      {content.contents.map((item, index) => {
                        return (
                          <FrontFeature
                            key={item.id}
                            title={item.title}
                            icon={item.icon}
                            description={item.description}
                          />
                        );
                      })}
                    </ContentGrid>
                  </ContentRow>
                </ContentBlock>
              );
            } else if (content.__typename === "FaqItem") {
              return (
                <ContentBlock key={content.id} background={theme.colorGrey1}>
                  <ContentRow>
                    <ContentTitle
                      title={content.title}
                      color={theme.colorGrey6}
                    />
                    <FrontFAQ />
                  </ContentRow>
                </ContentBlock>
              );
            } else if (content.__typename === "LicenseItem") {
              return (
                <ContentBlock key={content.id}>
                  <ContentRow>
                    <ContentTitle
                      title={content.title}
                      color={theme.colorGrey6}
                    />
                    <ContentGrid>
                      {content.contents.map((item, index) => {
                        return (
                          <FrontLicense
                            key={item.id}
                            title={item.title}
                            color={item.color}
                            features={item.features}
                          />
                        );
                      })}
                    </ContentGrid>
                  </ContentRow>
                </ContentBlock>
              );
            } else if (content.__typename === "CtaItem") {
              return (
                <ContentBlock key={content.id}>
                  <ContentRow>
                    <FrontCTA
                      title={content.title}
                      description={content.description}
                      button_icon={content.button_icon}
                      button_text={content.button_text}
                      button_link="http://cms.artwork-solutions.com/sites/default/files/Präsentation - Setzen von Gebrauchsinformationen und Broschüren - Frank Gilsebach.pdf"
                    />
                  </ContentRow>
                </ContentBlock>
              );
            } else if (content.__typename === "CodeBlockItem") {
              return (
                <ContentBlock key={content.id}>
                  <ContentRow>
                    <FrontCodeBlock title={content.title} code={content.code} />
                  </ContentRow>
                </ContentBlock>
              );
            }
            return home;
          })}
        </React.Fragment>
      )}
    </div>
  );
};

export default HomePage;
