import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import theme from "theme/theme";

const styles = css`
  font-family: ${theme.fontBodyText};
  font-size: ${theme.defaultFontSize};
  line-height: ${theme.defaultLineHeight};
  color: ${(props) => (props.reverse ? theme.colorGrey2 : props.color)};
  margin: 2rem 0 3rem;
  padding: ${(props) => (props.nobullet ? "0" : "0 0 0 2.5rem")};
`;

const OL = styled.ol`
  ${styles}
`;
const UL = styled.ul`
  ${styles};
  list-style: ${(props) =>
    props.nobullet ? "none inside none" : "circle inside none"};
`;

const List = ({ ordered, children, ...props }) => {
  return React.createElement(ordered ? OL : UL, props, children);
};

List.propTypes = {
  ordered: PropTypes.bool,
  color: PropTypes.string,
  reverse: PropTypes.bool,
  nobullet: PropTypes.bool,
};

List.defaultProps = {
  color: theme.colorGrey6,
};

export default List;
