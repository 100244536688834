import React from "react";
import styled from "styled-components";

import theme from "theme/theme";

import Heading from "components/atoms/Heading";
import List from "components/atoms/List";

const FrontLicenseStyle = styled.div`
  width: 19rem;
  margin: 0.8rem;
  background-color: ${theme.colorGrey1};

  border-top: solid 1px
    ${(props) => (props.color ? props.color : theme.colorPrimary)};
  border-bottom: solid 1px
    ${(props) => (props.color ? props.color : theme.colorPrimary)};
  border-radius: 4px;

  .box__header {
    color: ${theme.colorWhite};
    background-color: ${(props) =>
      props.color ? props.color : theme.colorPrimary};
    width: 100% !important;
    margin: 0 auto;
    padding: 1rem;
  }

  .box__text {
    padding: 2rem 1rem;
    color: ${theme.colorGrey5};

    ul {
      margin: 0;
      padding: 0;
      font-size: 1.6rem;
      line-height: 3rem;
      li {
        margin-bottom: 1.5rem;
      }
    }
  }
`;

const FrontLicense = (props) => {
  const getFeatures = (array) => {
    return array.map((item, index) => {
      return <li key={index}>{item}</li>;
    });
  };
  return (
    <FrontLicenseStyle color={props.color}>
      <Heading level={3} className="box__header">
        {props.title}
      </Heading>
      <div className="box__text">
        <List nobullet>{getFeatures(props.features)}</List>
      </div>
    </FrontLicenseStyle>
  );
};

export default FrontLicense;
