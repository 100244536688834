import React from "react";
import styled from "styled-components";

const ContentGridStyle = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: stretch;

  margin: 0;
  padding: 0;

  width: 100%;
  max-width: 100%;
`;

const ContentGrid = (props) => {
  return <ContentGridStyle>{props.children}</ContentGridStyle>;
};

export default ContentGrid;
