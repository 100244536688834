import React from "react";
import styled from "styled-components";

import theme from "theme/theme";

import Heading from "components/atoms/Heading";
import Paragraph from "components/atoms/Paragraph";
import Icon from "components/atoms/Icon";

const FrontValuePropositionStyle = styled.div`
  width: 22rem;
  margin: 3rem 1rem;
  background-color: ${theme.colorGrey1};

  border-top: solid 1px ${theme.colorPrimary};
  border-bottom: solid 1px ${theme.colorQuartiary};
  border-radius: 4px;

  .box__icon {
    background-color: ${theme.colorPrimary};
    width: 100% !important;
    margin: 0 auto;
    padding: 2rem 0;
  }

  .box__header {
    padding: 2rem 1rem 0;
    color: ${theme.colorQuartiary};
  }

  .box__text {
    padding: 0 1rem 2rem;
    color: ${theme.colorGrey5};
  }

  h3 {
    margin-bottom: 1rem;
  }
`;

const FrontValueProposition = (props) => {
  return (
    <FrontValuePropositionStyle>
      <Icon icon={props.icon} size="120" color="white" className="box__icon" />
      <Heading level={3} className="box__header">
        {props.title}
      </Heading>
      <Paragraph className="box__text util-paragraph-reduced">
        {props.description}
      </Paragraph>
    </FrontValuePropositionStyle>
  );
};

export default FrontValueProposition;
