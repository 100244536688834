import React from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import { LANDINGPAGE } from "gql/common";

import theme from "theme/theme";

import Heading from "components/atoms/Heading";
import Button from "components/atoms/Button";
import Paragraph from "components/atoms/Paragraph";
import Spinner from "components/atoms/Spinner";

const BlockNewsletterStyle = styled.div``;

const BlockNewsletter = () => {
  const { data, loading, error } = useQuery(LANDINGPAGE, {
    variables: { id: 3 },
  });

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <React.Fragment>
          {data.landingpage.contents.map((content, index) => {
            let nl_content = "";
            if (content.__typename === "ExternalLink") {
              nl_content = (
                <BlockNewsletterStyle key="index">
                  <Heading level={4}>{content.title}</Heading>
                  <Paragraph
                    dangerouslySetInnerHTML={{ __html: content.description }}
                  />
                  <Button
                    href={content.link_url}
                    paletteText={theme.colorGrey2}
                    palette={theme.colorSecondary}
                    height="40"
                  >
                    {content.link_title}
                  </Button>
                </BlockNewsletterStyle>
              );
            }
            return nl_content;
          })}
        </React.Fragment>
      )}
    </>
  );
};

export default BlockNewsletter;
