import React from "react";

import theme from "theme/theme";

import Heading from "components/atoms/Heading";
import Link from "components/atoms/Link";
import Paragraph from "components/atoms/Paragraph";

const ImprintPage = () => {
  return (
    <div className="page page__imprint">
      <div className="row">
        <Heading className="u-margin-bottom-large">
          <span className="main">Impressum</span>
          <span className="sub">Angaben gemäß § 5 TMG</span>
        </Heading>
        <Paragraph>
          FROHNE DRUCK GmbH
          <br />
          Rainer Schultze
        </Paragraph>
        <p>&nbsp;</p>
        <Heading level={3}>Postanschrift</Heading>
        <Paragraph>
          Nordstraße 8
          <br />
          57578 Elkenroth
        </Paragraph>
        <p>&nbsp;</p>
        <Heading level={3}>Kontakt</Heading>
        <Paragraph>
          Telefon: +49 (0) 2747 914 99-00
          <br />
          Telefax: +49 (0) 2747 914 99-02
          <br />
          E-Mail:&nbsp;
          <Link
            href="mailto:info@frohne-druck.de"
            color={theme.colorTertiary}
            background={theme.colorSecondaryDark}
            em
          >
            info@frohne-druck.de
          </Link>
        </Paragraph>
        <p>&nbsp;</p>

        <Heading level={3}>Vertreten durch</Heading>
        <Paragraph>Rainer Schultze</Paragraph>
        <p>&nbsp;</p>

        <Heading level={3}>Eingetragen am</Heading>
        <Paragraph>
          Montabaur
          <br />
          HRB 20161
        </Paragraph>
        <p>&nbsp;</p>

        <Heading level={3}>Umsatzsteuer-Identifikationsnummer</Heading>
        <Paragraph>DE283079684</Paragraph>
        <p>&nbsp;</p>

        <Heading level={2}>Hinweise zur Website</Heading>
        <Heading level={3}>Urheberrechtliche Hinweise</Heading>
        <Paragraph>
          <span>
            Photo by{" "}
            <a
              href="https://unsplash.com/@leladesign?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
              target="_blank"
              rel="noopener noreferrer"
            >
              Lea L
            </a>{" "}
            on{" "}
            <a
              href="https://unsplash.com/s/photos/laptop-grafik?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
              target="_blank"
              rel="noopener noreferrer"
            >
              Unsplash
            </a>
          </span>
          <br />
          Mockup vector created by brgfx{" "}
          <a
            href="https://www.freepik.com/vectors/mockup"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.freepik.com
          </a>
          <br />
          <span>
            Grafics by{" "}
            <a
              href="https://icomoon.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Icomoon.io
            </a>
          </span>
        </Paragraph>
        <p>&nbsp;</p>

        <Heading level={3}>
          Verantwortlich für journalistisch-redaktionelle Inhalte ist
        </Heading>
        <Paragraph>Rainer Schultze</Paragraph>
        <p>&nbsp;</p>

        <Heading level={3}>Information gemäß § 36 VSBG</Heading>
        <Paragraph>
          Gemäß § 36 VSBG (Verbraucherstreitbeilegungsgesetz – Gesetz über die
          alternative Streitbeilegung in Verbrauchersachen) erklärt der
          Betreiber dieser Website:
          <br />
          Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
          <br />
          Das Impressum wurde mit dem&nbsp;
          <Link
            href="https://www.activemind.de/datenschutz/impressums-generator/"
            target="_blank"
            color={theme.colorTertiary}
            background={theme.colorSecondaryDark}
            em
          >
            Impressums-Generator der activeMind AG
          </Link>
          erstellt.
        </Paragraph>
        <p>&nbsp;</p>
        <Heading level={3}>Haftung für Inhalte</Heading>
        <Paragraph>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
          auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
          §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
          verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
          Tätigkeit hinweisen.
        </Paragraph>
        <Paragraph>
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen.
        </Paragraph>
        <p>&nbsp;</p>
        <Heading level={3}>Haftung für Links</Heading>
        <Paragraph>
          Unser Angebot enthält Links zu externen Websites Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
        </Paragraph>
        <Paragraph>
          Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
          ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
        </Paragraph>
        <p>&nbsp;</p>
        <Heading level={3}>Urheberrecht</Heading>
        <Paragraph>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
        </Paragraph>
        <Paragraph>
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
        </Paragraph>
        <Paragraph>
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen.
        </Paragraph>
        <p>&nbsp;</p>

        <Heading level={3}>Konzept und Programmierung</Heading>
        <Paragraph>
          <Link
            href="https://www.smarteseiten.de"
            target="_blank"
            color={theme.colorTertiary}
            background={theme.colorSecondaryDark}
            em
          >
            Smarte Seiten
          </Link>
        </Paragraph>
        <p>&nbsp;</p>
      </div>
    </div>
  );
};

export default ImprintPage;
