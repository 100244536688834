import React from "react";
import styled from "styled-components";

import { respondTo } from "theme/utils/_respondTo";

import theme from "theme/theme";

import Button from "components/atoms/Button";
import Heading from "components/atoms/Heading";
import Paragraph from "components/atoms/Paragraph";
import Icon from "components/atoms/Icon";

const FrontCTAStyle = styled.div`
  ${respondTo.phone`
    max-width: 300px;
  `}
`;

const FrontCTA = (props) => {
  return (
    <FrontCTAStyle>
      <Heading level={2}>{props.title}</Heading>
      <Paragraph>{props.description}</Paragraph>
      <Button
        href={props.button_link}
        paletteText={theme.colorGrey1}
        palette={theme.colorSecondary}
        height="80"
      >
        <Icon icon={props.button_icon} size="20" color={theme.colorGrey1} />
        &nbsp;&nbsp;&nbsp;
        {props.button_text}
      </Button>
    </FrontCTAStyle>
  );
};

export default FrontCTA;
