import React from "react";

import theme from "theme/theme";

import Heading from "components/atoms/Heading";
import Link from "components/atoms/Link";
import List from "components/atoms/List";
import Paragraph from "components/atoms/Paragraph";

const PrivacyPage = () => {
  return (
    <div className="page page__privacy">
      <div className="row">
        <Heading className="u-margin-bottom-large">
          <span className="main">Datenschutzerklärung</span>
        </Heading>
        <Paragraph>
          Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der
          EU-Datenschutzgrundverordnung (DSGVO), ist:
        </Paragraph>
        <Paragraph>
          FROHNE DRUCK GmbH
          <br />
          Rainer Schultze
        </Paragraph>
        <Paragraph>
          Nordstraße 8
          <br />
          57578 Elkenroth
        </Paragraph>
        <Paragraph>
          Telefon: +49 (0) 2747 914 99-00
          <br />
          Telefax: +49 (0) 2747 914 99-02
          <br />
          E-Mail:&nbsp;
          <Link
            href="mailto:info@frohne-druck.de"
            color={theme.colorTertiary}
            background={theme.colorSecondaryDark}
            em
          >
            info@frohne-druck.de
          </Link>
        </Paragraph>
        <p>&nbsp;</p>
        <Heading level={2}>Ihre Betroffenenrechte</Heading>
        <Paragraph>
          Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten
          können Sie jederzeit folgende Rechte ausüben:
        </Paragraph>
        <List>
          <li>
            Auskunft über Ihre bei uns gespeicherten Daten und deren
            Verarbeitung (Art. 15 DSGVO),
          </li>
          <li>
            Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),
          </li>
          <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
          <li>
            Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
            gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),
          </li>
          <li>
            Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21
            DSGVO) und
          </li>
          <li>
            Datenübertragbarkeit, sofern Sie in die Datenverarbeitung
            eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben
            (Art. 20 DSGVO).
          </li>
        </List>
        <Paragraph>
          Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese
          jederzeit mit Wirkung für die Zukunft widerrufen.
        </Paragraph>
        <Paragraph>
          Sie können sich jederzeit mit einer Beschwerde an eine
          Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde des
          Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche
          Stelle zuständige Behörde.
        </Paragraph>
        <Paragraph>
          Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich)
          mit Anschrift finden Sie unter:&nbsp;
          <Link
            href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
            target="_blank"
            color={theme.colorTertiary}
            background={theme.colorWhite}
            em
          >
            https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
          </Link>
          .
        </Paragraph>
        <p>&nbsp;</p>
        <Heading level={2}>
          Erfassung allgemeiner Informationen beim Besuch unserer Website
        </Heading>
        <Heading level={3}>Art und Zweck der Verarbeitung:</Heading>
        <Paragraph>
          Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht
          registrieren oder anderweitig Informationen übermitteln, werden
          automatisch Informationen allgemeiner Natur erfasst. Diese
          Informationen (Server-Logfiles) beinhalten etwa die Art des
          Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres
          Internet-Service-Providers, Ihre IP-Adresse und ähnliches.{" "}
        </Paragraph>
        <Paragraph>
          Sie werden insbesondere zu folgenden Zwecken verarbeitet:
        </Paragraph>
        <List>
          <li>
            Sicherstellung eines problemlosen Verbindungsaufbaus der Website,
          </li>
          <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
          <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
          <li>zur Optimierung unserer Website.</li>
        </List>
        <Paragraph>
          Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu
          ziehen. Informationen dieser Art werden von uns ggfs. statistisch
          ausgewertet, um unseren Internetauftritt und die dahinterstehende
          Technik zu optimieren.
        </Paragraph>
        <p>&nbsp;</p>
        <Heading level={3}>Rechtsgrundlage und berechtigtes Interesse:</Heading>
        <Paragraph>
          Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis
          unseres berechtigten Interesses an der Verbesserung der Stabilität und
          Funktionalität unserer Website.
        </Paragraph>
        <p>&nbsp;</p>
        <Heading level={3}>Empfänger:</Heading>
        <Paragraph>
          Empfänger der Daten sind ggf. technische Dienstleister, die für den
          Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter tätig
          werden.
        </Paragraph>
        <p>&nbsp;</p>
        <Heading level={3}>Drittlandtransfer:</Heading>
        <Paragraph>
          Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen:
          <br />
          Nein
          <br />
          Folgende Datenschutzgarantien liegen vor:
        </Paragraph>
        <p>&nbsp;</p>
        <Heading level={3}>Speicherdauer:</Heading>
        <Paragraph>
          Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung
          nicht mehr erforderlich sind. Dies ist für die Daten, die der
          Bereitstellung der Website dienen, grundsätzlich der Fall, wenn die
          jeweilige Sitzung beendet ist.
          <br />
          Im Falle der Speicherung der Daten in Logfiles ist dies nach
          spätestens 14 Tagen der Fall. Eine darüberhinausgehende Speicherung
          ist möglich. In diesem Fall werden die IP-Adressen der Nutzer
          anonymisiert, sodass eine Zuordnung des aufrufenden Clients nicht mehr
          möglich ist.
        </Paragraph>
        <p>&nbsp;</p>
        <Heading level={3}>
          Bereitstellung vorgeschrieben oder erforderlich:
        </Heading>
        <Paragraph>
          Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder
          gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist
          jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht
          gewährleistet. Zudem können einzelne Dienste und Services nicht
          verfügbar oder eingeschränkt sein. Aus diesem Grund ist ein
          Widerspruch ausgeschlossen.
        </Paragraph>
        <p>&nbsp;</p>

        <Heading level={2}>Cookies</Heading>
        <Paragraph>
          Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“.
          Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrem
          Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn
          Sie unsere Webseite besuchen.
          <br />
          Sie können Sie einzelne Cookies oder den gesamten Cookie-Bestand
          löschen. Darüber hinaus erhalten Sie Informationen und Anleitungen,
          wie diese Cookies gelöscht oder deren Speicherung vorab blockiert
          werden können. Je nach Anbieter Ihres Browsers finden Sie die
          notwendigen Informationen unter den nachfolgenden Links:
        </Paragraph>
        <List>
          <li>
            Mozilla
            Firefox: https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen
          </li>
          <li>
            Internet
            Explorer: https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
          </li>
          <li>
            Google
            Chrome: https://support.google.com/accounts/answer/61416?hl=de
          </li>
          <li>Opera: http://www.opera.com/de/help</li>
          <li>
            Safari: https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE
          </li>
        </List>
        <Heading level={3}>Speicherdauer und eingesetzte Cookies:</Heading>
        <Paragraph>
          Soweit Sie uns durch Ihre Browsereinstellungen oder Zustimmung die
          Verwendung von Cookies erlauben, können folgende Cookies auf unseren
          Webseiten zum Einsatz kommen:
          <br />
          Session Cookie, 1 Jahr
        </Paragraph>
        <p>&nbsp;</p>

        <Heading level={2}>Technisch notwendige Cookies</Heading>
        <Heading level={3}>Art und Zweck der Verarbeitung:</Heading>
        <Paragraph>
          Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu
          gestalten. Einige Elemente unserer Internetseite erfordern es, dass
          der aufrufende Browser auch nach einem Seitenwechsel identifiziert
          werden kann. Der Zweck der Verwendung technisch notwendiger Cookies
          ist, die Nutzung von Websites für die Nutzer zu vereinfachen. Einige
          Funktionen unserer Internetseite können ohne den Einsatz von Cookies
          nicht angeboten werden. Für diese ist es erforderlich, dass der
          Browser auch nach einem Seitenwechsel wiedererkannt wird.
          <br />
          Für folgende Anwendungen benötigen wir Cookies:
          <br />
          Übernahme von Spracheinstellungen
        </Paragraph>
        <Heading level={3}>Rechtsgrundlage und berechtigtes Interesse:</Heading>
        <Paragraph>
          Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis
          unseres berechtigten Interesses an einer nutzerfreundlichen Gestaltung
          unserer Website.
        </Paragraph>
        <Heading level={3}>Empfänger:</Heading>
        <Paragraph>
          Empfänger der Daten sind ggf. technische Dienstleister, die für den
          Betrieb und die Wartung unserer Website als Auftragsverarbeiter tätig
          werden.
        </Paragraph>
        <Heading level={3}>Drittlandtransfer:</Heading>
        <Paragraph>
          Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen:
          <br />
          Nein
          <br />
          Folgende Datenschutzgarantien liegen vor:{" "}
        </Paragraph>
        <Heading level={3}>
          Bereitstellung vorgeschrieben oder erforderlich:
        </Heading>
        <Paragraph>
          Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder
          gesetzlich noch vertraglich vorgeschrieben. Ohne diese Daten ist
          jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht
          gewährleistet. Zudem können einzelne Dienste und Services nicht
          verfügbar oder eingeschränkt sein.
        </Paragraph>
        <Heading level={3}>Widerspruch</Heading>
        <Paragraph>
          Lesen Sie dazu die Informationen über Ihr Widerspruchsrecht nach Art.
          21 DSGVO weiter unten.
        </Paragraph>
        <p>&nbsp;</p>

        <Heading level={2}>Newsletter</Heading>
        <Heading level={3}>Art und Zweck der Verarbeitung:</Heading>
        <Paragraph>
          Für die Zustellung unseres Newsletters erheben wir personenbezogene
          Daten, die über eine Eingabemaske an uns übermittelt werden.
          <br />
          Für eine wirksame Registrierung benötigen wir eine valide
          E-Mail-Adresse. Um zu überprüfen, dass eine Anmeldung tatsächlich
          durch den Inhaber einer E-Mail-Adresse erfolgt, setzen wir das
          „Double-Opt-in“-Verfahren ein. Hierzu protokollieren wir die Anmeldung
          zum Newsletter, den Versand einer Bestätigungsmail und den Eingang der
          hiermit angeforderten Antwort. Weitere Daten werden nicht erhoben.
        </Paragraph>
        <Heading level={3}>Rechtsgrundlage:</Heading>
        <Paragraph>
          Auf Grundlage Ihrer ausdrücklich erteilten Einwilligung (Art. 6 Abs. 1
          lit. a DSGVO), übersenden wir Ihnen regelmäßig unseren Newsletter bzw.
          vergleichbare Informationen per E-Mail an Ihre angegebene
          E-Mail-Adresse.
          <br />
          Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer
          Nutzung für den Newsletterversand können Sie jederzeit mit Wirkung für
          die Zukunft widerrufen. In jedem Newsletter findet sich dazu ein
          entsprechender Link. Außerdem können Sie sich jederzeit auch direkt
          auf dieser Website abmelden oder uns Ihren Widerruf über die am Ende
          dieser Datenschutzhinweise angegebene Kontaktmöglichkeit mitteilen.
        </Paragraph>
        <Heading level={3}>Empfänger:</Heading>
        <Paragraph>
          Empfänger der Daten sind ggf. Auftragsverarbeiter.
        </Paragraph>
        <Heading level={3}>Drittlandtransfer:</Heading>
        <Paragraph>
          Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen:
          <br />
          Nein
          <br />
          Folgende Datenschutzgarantien liegen vor:
        </Paragraph>
        <Heading level={3}>Speicherdauer:</Heading>
        <Paragraph>
          Die Daten werden in diesem Zusammenhang nur verarbeitet, solange die
          entsprechende Einwilligung vorliegt. Danach werden sie gelöscht.
        </Paragraph>
        <Heading level={3}>
          Bereitstellung vorgeschrieben oder erforderlich:
        </Heading>
        <Paragraph>
          Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig,
          allein auf Basis Ihrer Einwilligung. Ohne bestehende Einwilligung
          können wir Ihnen unseren Newsletter leider nicht zusenden.
        </Paragraph>
        <Heading level={3}>Widerruf der Einwilligung:</Heading>
        <Paragraph>
          Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer
          Nutzung für den Newsletterversand können Sie jederzeit mit Wirkung für
          die Zukunft widerrufen. Die Abmeldung kann über den in jeder E-Mail
          enthaltenen Link oder beim unten aufgeführten Datenschutzbeauftragten
          bzw. der für den Datenschutz zuständigen Person beantragt werden.
        </Paragraph>
        <p>&nbsp;</p>

        <Heading level={2}>Verwendung von Google Analytics</Heading>
        <Paragraph>
          Soweit Sie ihre Einwilligung gegeben haben, wird auf dieser Website
          Google Analytics eingesetzt, ein Webanalysedienst der Google LLC, 1600
          Amphitheatre Parkway, Mountain View, CA 94043 USA (nachfolgend:
          „Google“). Google Analytics verwendet sog. „Cookies“, also
          Textdateien, die auf Ihrem Computer gespeichert werden und die eine
          Analyse der Benutzung der Webseite durch Sie ermöglichen. Die durch
          das Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite
          werden in der Regel an einen Server von Google in den USA übertragen
          und dort gespeichert. Aufgrund der Aktivierung der IP-Anonymisierung
          auf diesen Webseiten, wird Ihre IP-Adresse von Google jedoch innerhalb
          von Mitgliedstaaten der Europäischen Union oder in anderen
          Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
          zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an
          einen Server von Google in den USA übertragen und dort gekürzt. Die im
          Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse
          wird nicht mit anderen Daten von Google zusammengeführt.
        </Paragraph>
        <Paragraph>
          Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie
          unter https://www.google.com/analytics/terms/de.html und unter
          https://policies.google.com/?hl=de.
        </Paragraph>
        <Paragraph>
          Im Auftrag des Betreibers dieser Website wird Google diese
          Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um
          Reports über die Webseitenaktivitäten zusammenzustellen und um weitere
          mit der Websitenutzung und der Internetnutzung verbundene
          Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen.
        </Paragraph>
        <Paragraph>
          Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B.
          User-ID) oder Werbe-IDs verknüpften Daten werden nach 14 Monaten
          automatisch gelöscht. Die Löschung von Daten, deren Aufbewahrungsdauer
          erreicht ist, erfolgt automatisch einmal im Monat.
        </Paragraph>
        <Heading level={3}>Widerruf der Einwilligung:</Heading>
        <Paragraph>
          Sie können das Tracking durch Google Analytics auf unserer Website
          unterbinden, indem Sie
          <Link
            href="https://www.activemind.de/datenschutz/generatoren/datenschutzerklaerung/ergebnis/#"
            target="_blank"
            color={theme.colorTertiary}
            background={theme.colorWhite}
            em
          >
            diesen Link anklicken
          </Link>
          . Dabei wird ein Opt-out-Cookie auf Ihrem Gerät installiert. Damit
          wird die Erfassung durch Google Analytics für diese Website und für
          diesen Browser zukünftig verhindert, solange das Cookie in Ihrem
          Browser installiert bleibt.
          <br />
          Sie können darüber hinaus die Speicherung der Cookies durch eine
          entsprechende Einstellung Ihrer Browser-Software verhindern; wir
          weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls
          nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen
          können.
          <br />
          Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten
          und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer
          IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
          verhindern, indem sie das unter dem folgenden Link verfügbare
          Browser-Plugin herunterladen und installieren:
          <Link
            href="http://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
            color={theme.colorTertiary}
            background={theme.colorSecondaryDark}
            em
          >
            Browser Add On zur Deaktivierung von Google Analytics
          </Link>
          .
        </Paragraph>
        <p>&nbsp;</p>

        <Heading level={2}>Eingebettete YouTube-Videos</Heading>
        <Paragraph>
          Auf unserer Website betten wir YouTube-Videos ein. Betreiber der
          entsprechenden Plugins ist die YouTube, LLC, 901 Cherry Ave., San
          Bruno, CA 94066, USA (nachfolgend „YouTube“). Die YouTube, LLC ist
          einer Tochtergesellschaft der Google LLC, 1600 Amphitheatre Pkwy,
          Mountain View, CA 94043, USA (nachfolgend „Google“). Wenn Sie eine
          Seite mit dem YouTube-Plugin besuchen, wird eine Verbindung zu Servern
          von YouTube hergestellt. Dabei wird YouTube mitgeteilt, welche Seiten
          Sie besuchen. Wenn Sie in Ihrem YouTube-Account eingeloggt sind, kann
          YouTube Ihr Surfverhalten Ihnen persönlich zuzuordnen. Dies verhindern
          Sie, indem Sie sich vorher aus Ihrem YouTube-Account ausloggen.
          <br />
          Wird ein YouTube-Video gestartet, setzt der Anbieter Cookies ein, die
          Hinweise über das Nutzerverhalten sammeln.
          <br />
          Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer
          Verarbeitung durch YouTube erhalten Sie in den Datenschutzerklärungen
          des Anbieters, Dort erhalten Sie auch weitere Informationen zu Ihren
          diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze
          Ihrer Privatsphäre (https://policies.google.com/privacy).
        </Paragraph>
        <Heading level={3}>Widerruf der Einwilligung:</Heading>
        <Paragraph>
          Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen
          Opt-out oder ein Blockieren der Datenübertragung angeboten. Wenn Sie
          eine Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern
          wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung
          für die entsprechende Cookie-Kategorie oder alle technisch nicht
          notwendigen Cookies und Datenübertragungen. In diesem Fall können Sie
          unsere Website jedoch ggfs. nicht oder nur eingeschränkt nutzen.
        </Paragraph>
        <p>&nbsp;</p>

        <Heading level={2}>Google AdWords</Heading>
        <Paragraph>
          Unsere Website nutzt das Google Conversion-Tracking.
          Betreibergesellschaft der Dienste von Google AdWords ist die Google
          LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Sind Sie
          über eine von Google geschaltete Anzeige auf unsere Webseite gelangt,
          wird von Google Adwords ein Cookie auf Ihrem Rechner gesetzt. Das
          Cookie für Conversion-Tracking wird gesetzt, wenn ein Nutzer auf eine
          von Google geschaltete Anzeige klickt.
          <br />
          Besucht der Nutzer bestimmte Seiten unserer Website und das Cookie ist
          noch nicht abgelaufen, können wir und Google erkennen, dass der Nutzer
          auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde.
          Jeder Google AdWords-Kunde erhält ein anderes Cookie. Cookies können
          somit nicht über die Websites von AdWords-Kunden nachverfolgt werden.
          Die mithilfe des Conversion-Cookies eingeholten Informationen dienen
          dazu, Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich
          für Conversion-Tracking entschieden haben. Die Kunden erfahren die
          Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu
          einer mit einem Conversion-Tracking-Tag versehenen Seite
          weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit
          denen sich Nutzer persönlich identifizieren lassen.
          <br />
          Nähere Informationen über die Datenverarbeitung durch Google können
          Sie den Google-Datenschutzhinweisen
          entnehmen: https://policies.google.com/privacy. Dort können Sie im
          Datenschutzcenter auch Ihre persönlichen Datenschutz-Einstellungen
          verändern.
        </Paragraph>
        <Heading level={3}>Widerruf der Einwilligung:</Heading>
        <Paragraph>
          Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen
          Opt-out oder ein Blockieren der Datenübertragung angeboten. Wenn Sie
          eine Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern
          wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung
          für die entsprechende Cookie-Kategorie oder alle technisch nicht
          notwendigen Cookies und Datenübertragungen. In diesem Fall können Sie
          unsere Website jedoch ggfs. nicht oder nur eingeschränkt nutzen.
        </Paragraph>
        <p>&nbsp;</p>

        <Heading level={2}>Einsatz von Google Remarketing</Heading>
        <Paragraph>
          Diese Website verwendet die Remarketing-Funktion der Google Inc.
          Betreibergesellschaft der Dienste von Google Remarketing ist die
          Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA
          (nachfolgend „Google“).
          <br />
          Die Funktion dient dazu, Websitebesuchern innerhalb des
          Google-Werbenetzwerks interessenbezogene Werbeanzeigen zu
          präsentieren. Im Browser des Websitebesuchers wird ein sog. „Cookie“
          gespeichert, der es ermöglicht, den Besucher wiederzuerkennen, wenn
          dieser Webseiten aufruft, die dem Werbenetzwerk von Google angehören.
          Auf diesen Seiten können dem Besucher Werbeanzeigen präsentiert
          werden, die sich auf Inhalte beziehen, die der Besucher zuvor auf
          Webseiten aufgerufen hat, die die Remarketing Funktion von Google
          verwenden.
          <br />
          Nähere Informationen über die Datenverarbeitung durch Google können
          Sie den Google-Datenschutzhinweisen
          entnehmen: https://policies.google.com/privacy. Dort können Sie im
          Datenschutzcenter auch Ihre persönlichen Datenschutz-Einstellungen
          verändern.
        </Paragraph>
        <p>&nbsp;</p>

        <Heading level={2}>Widerruf der Einwilligung:</Heading>
        <Paragraph>
          Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen
          Opt-out oder ein Blockieren der Datenübertragung angeboten. Wenn Sie
          eine Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern
          wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung
          für die entsprechende Cookie-Kategorie oder alle technisch nicht
          notwendigen Cookies und Datenübertragungen. In diesem Fall können Sie
          unsere Website jedoch ggfs. nicht oder nur eingeschränkt nutzen.
        </Paragraph>
        <p>&nbsp;</p>

        <Heading level={2}>SSL-Verschlüsselung</Heading>
        <Paragraph>
          Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen,
          verwenden wir dem aktuellen Stand der Technik entsprechende
          Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
        </Paragraph>
        <p>&nbsp;</p>

        <Heading level={2}>
          Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO
        </Heading>
        <Heading level={3}>Einzelfallbezogenes Widerspruchsrecht</Heading>
        <Paragraph>
          Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
          Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender
          personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO
          (Datenverarbeitung auf der Grundlage einer Interessenabwägung)
          erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese
          Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.
          <br />
          Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten
          nicht mehr verarbeiten, es sei denn, wir können zwingende
          schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
          Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung
          dient der Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen.
        </Paragraph>

        <Heading level={3}>Empfänger eines Widerspruchs</Heading>
        <Paragraph>
          FROHNE DRUCK GmbH, vertreten durch den Geschäftsführer Rainer Schultze
          <br />
          Nordstraße 8
          <br />
          57578 Elkenroth
        </Paragraph>
        <Paragraph>
          Telefon: +49 (0) 27 47 / 914 99 00
          <br />
          Telefax: +49 (0) 27 47 / 914 99 02
          <br />
          E-Mail:&nbsp;
          <Link
            href="mailto:info@frohne-druck.de"
            color={theme.colorTertiary}
            background={theme.colorSecondaryDark}
            em
          >
            info@frohne-druck.de
          </Link>
        </Paragraph>
        <p>&nbsp;</p>

        <Heading level={2}>Änderung unserer Datenschutzbestimmungen</Heading>
        <Paragraph>
          Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie
          stets den aktuellen rechtlichen Anforderungen entspricht oder um
          Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen,
          z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt
          dann die neue Datenschutzerklärung.
        </Paragraph>
        <p>&nbsp;</p>

        <Heading level={2}>Fragen an den Datenschutzbeauftragten</Heading>
        <Paragraph>
          Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine
          E-Mail oder wenden Sie sich direkt an die für den Datenschutz
          verantwortliche Person in unserer Organisation:
          <Link
            href="mailto:info@frohne-druck.de"
            color={theme.colorTertiary}
            background={theme.colorSecondaryDark}
            em
          >
            info@frohne-druck.de
          </Link>
        </Paragraph>
        <p>&nbsp;</p>

        <Paragraph>
          <em>
            Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt,
            den Experten für&nbsp;
            <Link
              href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/"
              target="_blank"
              color={theme.colorTertiary}
              background={theme.colorSecondaryDark}
              em
            >
              externe Datenschutzbeauftragte
            </Link>{" "}
            (Version #2020-09-30).
          </em>
        </Paragraph>
        <p>&nbsp;</p>
      </div>
    </div>
  );
};

export default PrivacyPage;
