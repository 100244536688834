import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

import theme from "theme/theme";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  position: relative;
  border: 0.2em solid
    ${(props) => (props.reverse ? theme.colorGrey6 : theme.colorGrey2)};
  border-bottom-color: ${(props) => props.palette};
  border-radius: 50%;
  margin: 0 auto;
  width: 1em;
  height: 1em;
  animation: ${spin} 1s linear infinite;
`;

Spinner.propTypes = {
  palette: PropTypes.string,
  reverse: PropTypes.bool,
};

Spinner.defaultProps = {
  palette: theme.colorPrimary,
};

export default Spinner;
