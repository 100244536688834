import React from "react";
import styled from "styled-components";

import { useQuery } from "@apollo/react-hooks";
import { LANDINGPAGE } from "gql/common";

import Spinner from "components/atoms/Spinner";
import Paragraph from "components/atoms/Paragraph";

const BlockUSPStyle = styled.div``;

const BlockUSP = () => {
  const { data, loading, error } = useQuery(LANDINGPAGE, {
    variables: { id: 3 },
  });
  return (
    <BlockUSPStyle>
      {loading ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <Paragraph
            dangerouslySetInnerHTML={{ __html: data.landingpage.usp }}
          />
        </React.Fragment>
      )}
    </BlockUSPStyle>
  );
};

export default BlockUSP;
