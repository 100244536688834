import React from "react";
import styled from "styled-components";

import Paragraph from "components/atoms/Paragraph";

const FrontParagraphStyle = styled.div``;

const FrontParagraph = (props) => {
  return (
    <FrontParagraphStyle>
      <Paragraph dangerouslySetInnerHTML={{ __html: props.text }} />
    </FrontParagraphStyle>
  );
};

export default FrontParagraph;
