import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import theme from "theme/theme";

const styles = css`
  backface-visibility: hidden;
  font-family: ${theme.fontHeadings};
  font-size: ${(props) =>
    props.level === 1
      ? "4rem"
      : props.level === 2
      ? "3.6rem"
      : props.level === 3
      ? "2rem"
      : props.level === 4
      ? "1.9rem"
      : theme.defaultFontSize};
  line-height: 1.25;
  font-weight: normal;
  letter-spacing: 0;
  color: ${(props) => (props.reverse ? theme.colorGrey2 : props.color)};
  margin: ${(props) =>
    props.level === 1
      ? "0 0 3.5rem"
      : props.level === 2
      ? "0 0 2.5rem"
      : props.level === 3
      ? "0 0 2rem"
      : props.level === 4
      ? "0 0 1.5rem"
      : theme.defaultFontSize};

  .main {
    font-size: 4.2rem;
    letter-spacing: 1rem !important;
    text-transform: uppercase;
  }

  .sub {
    font-size: 2rem;
  }

  span {
    display: block;
  }
`;

const Heading = styled(({ level, children, reverse, color, theme, ...props }) =>
  React.createElement(`h${level}`, props, children)
)`
  ${styles}
`;

Heading.propTypes = {
  level: PropTypes.number,
  children: PropTypes.node,
  color: PropTypes.string,
  reverse: PropTypes.bool,
};

Heading.defaultProps = {
  level: 1,
  color: theme.colorGrey6,
};

export default Heading;
