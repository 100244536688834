import React from "react";

import LayoutStyle from "./Layout.styles";

import Header from "components/organisms/Header";
import Footer from "components/organisms/Footer";

const Layout = (props) => {
  return (
    <LayoutStyle>
      <header className="header">
        <Header />
      </header>
      <main className="content">{props.children}</main>
      <footer className="footer">
        <Footer />
      </footer>
    </LayoutStyle>
  );
};

export default Layout;
