import React from "react";
import styled from "styled-components";

const FrontCodeBlockStyle = styled.div``;

const FrontCodeBlock = (props) => {
  return (
    <FrontCodeBlockStyle>
      <h2>{props.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: props.code }} />
    </FrontCodeBlockStyle>
  );
};

export default FrontCodeBlock;
