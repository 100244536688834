import React from "react";
import styled from "styled-components";

import Paragraph from "components/atoms/Paragraph";

const FrontUSPStyle = styled.div``;

const FrontUSP = (props) => {
  return (
    <FrontUSPStyle className="util-margin-bottom-medium">
      <Paragraph dangerouslySetInnerHTML={{ __html: props.data }} />
    </FrontUSPStyle>
  );
};

export default FrontUSP;
