import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import { darken } from "polished";

import theme from "theme/theme";

const styles = css`
  position: relative;
  display: inline;
  text-decoration: none;
  font-family: ${theme.fontBodyText};
  font-size: ${theme.defaultFontSize};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  font-style: ${(props) => (props.em ? "italic" : "normal")};
  color: ${(props) => (props.reverse ? theme.colorGrey2 : props.color)};
  background: linear-gradient(
    to right,
    ${(props) =>
      props.reverse
        ? theme.colorGrey4 && darken(0.2, theme.colorGrey4)
        : props.background},
    ${(props) =>
        props.reverse
          ? theme.colorGrey3
          : props.background && darken(0.2, props.background)}
      50%,
    ${(props) => (props.reverse ? theme.colorGrey5 : props.color)} 50%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  overflow: hidden;

  transition: all 0.3s ease-in-out;

  &:hover {
    background-position: 0 100%;
  }
`;

const StyledNavLink = styled(({ theme, reverse, color, ...props }) => (
  <NavLink {...props} />
))`
  ${styles}
`;

const Anchor = styled.a`
  ${styles}
`;

const Link = ({ ...props }) => {
  const { to } = props;
  if (to) {
    return <StyledNavLink {...props} />;
  }
  return <Anchor {...props} />;
};

Link.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string,
  reverse: PropTypes.bool,
  to: PropTypes.string,
  bold: PropTypes.bool,
  em: PropTypes.bool,
};

Link.defaultProps = {
  color: theme.colorGrey6,
  background: theme.colorGrey6,
};

export default Link;
