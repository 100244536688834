import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { CloudimageProvider } from "react-cloudimage-responsive";

import ApolloClient from "apollo-client";
import { ApolloProvider } from "react-apollo";

import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import introspectionQueryResultData from "./fragmentTypes.json";

import Router from "./router";
import ScrollToTop from "components/atoms/ScrollToTop";

import GlobalStyle from "./theme/styles/globalstyle";
import "./theme/fonts/fonts.css";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const httpLink = new HttpLink({
  // uri: "https://jafrohnelpdocuclean.lndo.site/graphql",
  uri: "http://cms.artwork-solutions.com/graphql",
});
const cache = new InMemoryCache({ fragmentMatcher });

const client = new ApolloClient({
  cache,
  link: httpLink,
});

const cloudImageConfig = {
  token: "amgdacnnfo",
  baseURL: "https://img.jurkitsch.digital/dominik.jurkitsch.digital/",
  presets: {
    phone: "(max-width: 37.5em)",
    tport: "(max-width: 56.25em)",
    tland: "(max-width: 75em)",
    desktop: "(max-width: 112.5em)",
  },
};

const App = () => {
  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <CloudimageProvider config={cloudImageConfig}>
          <Router />
          <ScrollToTop />
          <GlobalStyle />
        </CloudimageProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
