import { css } from "styled-components";

import { respondTo } from "theme/utils/_respondTo";

const Layout = css`
  .row {
    max-width: 70vw;
    height: 100%;
    margin: 0 auto;

    ${respondTo.desktop`
      max-width: 75vw;
    `}

    ${respondTo.tland`
    max-width: 80vw;
  `}

  ${respondTo.tport`
    max-width: 85vw;
  `}

  ${respondTo.phone`
    width: 100%;
    max-width: 90vw;
  `}
  }
`;

export default Layout;
