const theme = {};

/**
 * The color scheme
 */
theme.colorPrimaryLight = "rgba(0,161,156,0.43)"; // #00a19c
theme.colorPrimary = "rgba(0,161,156)"; //
theme.colorPrimaryDark = "rgba(0,161,156,0.88)"; // #00869a

theme.colorSecondaryLight = "#007cba";
theme.colorSecondary = "#006ba1";
theme.colorSecondaryDark = "#005a87";

theme.colorTertiaryLight = "#F19397";
theme.colorTertiary = "rgb(94,178,191)"; // #32b1ad
theme.colorTertiaryDark = "#D11922";

theme.colorQuartiary = "rgb(94,195,192)"; // #5ec3c0

theme.colorBackgroundLight = "#677588";
theme.colorBackground = "#363D47"; // VITA DARK BLACK rgb(54, 61, 71) || CMYK (72, 58, 46, 53)
theme.colorBackgroundDark = "#252A31";

theme.colorWhite = "#FFFFFF";
theme.colorGrey1 = "#EEEEEE";
theme.colorGrey2 = "#E0E0E0";
theme.colorGrey3 = "#BDBDBD";
theme.colorGrey4 = "#9e9e9e";
theme.colorGrey5 = "#616161";
theme.colorGrey6 = "#494949";
theme.colorGrey7 = "#212121";
theme.colorBlack = "#000000";

/**
 * Font and text specific
 */
theme.fontBodyText = "'PT Sans', sans-serif";
theme.fontHeadings = "'PT Sans', sans-serif";

theme.defaultFontSize = "2rem";
theme.defaultLineHeight = "4rem";

/**
 * Grid system
 */
theme.gridWidth = "120rem";
theme.gutterHorizontal = "6rem";
theme.gutterVertical = "8rem";

/**
 * General settings
 */
theme.breakpoints = {
  phone: "37.5em",
  tport: "56.25em",
  tland: "75em",
  desktop: "112.5em",
};

export default theme;
