import React from "react";
import styled from "styled-components";

import FooterInfo from "../FooterInfo";
import FooterBar from "components/molecules/FooterBar";

const FooterStyle = styled.div``;

const Footer = (props) => {
  return (
    <FooterStyle>
      <FooterInfo>
        <div className="row">{props.children}</div>
      </FooterInfo>
      <FooterBar />
    </FooterStyle>
  );
};

export default Footer;
