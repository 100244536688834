import React from "react";
import styled from "styled-components";

import theme from "theme/theme";

import Heading from "components/atoms/Heading";
import Link from "components/atoms/Link";
import Paragraph from "components/atoms/Paragraph";

const BlockAddressStyle = styled.div``;

const BlockAddress = () => {
  return (
    <BlockAddressStyle>
      <Heading level={4}>FROHNE DRUCK GmbH</Heading>
      <Paragraph>Rainer Schultze</Paragraph>
      <Paragraph>
        Nordstraße 8
        <br />
        57578 Elkenroth
      </Paragraph>
      <Paragraph>
        T +49 2747 914 99-00
        <br />
        F +49 2747 914 99-02
        <br />
        E-Mail:&nbsp;
        <Link
          href="mailto:info@frohne-druck.de"
          color={theme.colorGrey1}
          background={theme.colorSecondaryDark}
          em
        >
          info@frohne-druck.de
        </Link>
      </Paragraph>
    </BlockAddressStyle>
  );
};

export default BlockAddress;
