import { createGlobalStyle } from "styled-components";

import { respondTo } from "theme/utils/_respondTo";
import theme from "theme/theme";

import Layout from "./_layout";
import Utilities from "./_utilities";

const GlobalStyle = createGlobalStyle`

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    font-size: 75%;

    ${respondTo.desktop`
      font-size: 70%;
    `}

    ${respondTo.tland`
      font-size: 65.25%;
    `}

    ${respondTo.tport`
      font-size: 58.5%;
    `}

  }

  body {
    box-sizing: border-box;
    font-family: ${theme.fontBodyText};
    font-weight: 400;
    line-height: 1.8;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${theme.colorGrey7};
    background-color: ${theme.colorWhite};
  }

  p {
    margin: 0 0 3.2rem;
  }

  ${Layout};
  ${Utilities};

`;

export default GlobalStyle;
