import React from "react";
import styled from "styled-components";

import theme from "theme/theme";

import Heading from "components/atoms/Heading";
import Paragraph from "components/atoms/Paragraph";
import Icon from "components/atoms/Icon";

const FrontFeatureStyle = styled.div`
  width: 26rem;
  margin: 4rem 1rem 0;

  .box__icon {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    svg {
      width: 140px;
      height: 140px;
      padding: 2rem;
      border-radius: 100px;
      background-color: ${theme.colorSecondaryDark};
    }
  }

  .box__header {
    padding: 2rem 0 0;
    color: ${theme.colorGrey6};
    text-align: center;
  }

  .box__text {
    border-top: solid 1px ${theme.colorGrey4};
    padding: 1.5rem 1rem 2rem;
    color: ${theme.colorGrey6};
  }
`;

const FrontFeature = (props) => {
  return (
    <FrontFeatureStyle className="box">
      <div className="box__icon">
        <Icon icon={props.icon} size="120" color={theme.colorWhite} />
      </div>
      <Heading level={3} className="box__header">
        {props.title}
      </Heading>
      <Paragraph className="box__text util-paragraph-reduced">
        {props.description}
      </Paragraph>
    </FrontFeatureStyle>
  );
};

export default FrontFeature;
