import styled from "styled-components";

import theme from "theme/theme";

import Line from "theme/images/line.svg";
import Plus from "theme/images/plus.svg";

const FrontFAQStyle = styled.div`
  .faq-box {
    margin: 20px 0;
    background-color: ${theme.colorWhite};

    border: solid 1px ${theme.colorPrimary};
    border-radius: 4px;

    h4 {
      margin-bottom: 0;
    }

    &__question_box {
      color: ${theme.colorWhite};
      background-color: ${theme.colorPrimary};
      cursor: pointer;
    }

    &__question_box.active {
      background-color: ${theme.colorSecondary};
    }

    &__question_box:hover {
      background-color: ${theme.colorPrimaryDark};
    }

    &__answer_box {
      padding: 2rem 2rem 1rem;
      overflow: hidden;
    }
  }

  .panel-close {
    padding: 0;
    opacity: 0;
    height: 0;
    transition-timing-function: ease-out;
    transition: all 0.4s;
  }

  .panel-open {
    opacity: 1;
    height: auto;
    transition-timing-function: ease-in;
    transition: all 0.4s;
  }

  .title-wrapper {
    display: block;
    position: relative;
    width: 100%;
    padding: 1rem 2rem;
  }

  .title-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 1.5rem;
    top: 1rem;
  }

  .minus {
    content: url(${Line});
  }

  .plus {
    content: url(${Plus});
    transform: rotate(90deg);
    transition-timing-function: ease-in;
    transition: all 0.4s;
  }
`;

export default FrontFAQStyle;
