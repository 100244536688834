import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import theme from "theme/theme";

const fontSize = ({ height }) => `${height / 40}rem`;

const backgroundColor = ({ transparent, disabled, palette }) =>
  transparent ? "transparent" : disabled ? theme.colorGrey2 : palette;
const foregroundColor = ({ transparent, disabled, palette, paletteText }) =>
  transparent ? theme.colorGrey6 : disabled ? theme.colorGrey5 : paletteText;

const hoverBackgroundColor = ({ disabled, transparent, palette }) =>
  transparent ? "transparent" : disabled ? theme.colorGrey2 : palette;
const hoverForegroundColor = ({
  disabled,
  transparent,
  palette,
  paletteText,
}) =>
  transparent ? theme.colorGrey6 : disabled ? theme.colorGrey5 : paletteText;

const styles = css`
  display: inline-flex;
  font-family: ${theme.fontBodyText};
  align-items: center;
  white-space: nowrap;
  font-size: ${fontSize};

  height: 2.5em;
  justify-content: center;
  text-decoration: none;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  appearance: none;
  padding: 0 1em;
  border-radius: 0.125em;
  box-sizing: border-box;

  transition: background-color 250ms ease-out, color 250ms ease-out,
    border-color 250ms ease-out;
  background-color: ${backgroundColor};
  color: ${foregroundColor};

  &:hover,
  &:focus,
  &:active {
    background-color: ${hoverBackgroundColor};
    color: ${hoverForegroundColor};
  }

  &:focus {
    outline: none;
  }
`;

const Anchor = styled.a`
  ${styles}
`;
const StyledButton = styled.button`
  ${styles}
`;
const StyledLink = styled(
  ({ disabled, transparent, reverse, palette, height, theme, ...props }) => (
    <Link {...props} />
  )
)`
  ${styles}
`;

const Button = ({ type, ...props }) => {
  const { to, href } = props;
  if (to) {
    return <StyledLink {...props} />;
  }
  if (href) {
    return <Anchor {...props} target="_blank" />;
  }
  return <StyledButton {...props} type={type} />;
};

Button.propTypes = {
  disabled: PropTypes.bool,
  palette: PropTypes.string,
  paletteText: PropTypes.string,
  transparent: PropTypes.bool,
  reverse: PropTypes.bool,
  height: PropTypes.string,
  type: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
};

Button.defaultProps = {
  palette: theme.colorPrimary,
  paletteText: theme.colorGrey1,
  type: "button",
  height: 40,
};

export default Button;
