import React from "react";
import styled from "styled-components";
import theme from "theme/theme";

import Logo from "components/molecules/Logo";
import LogoImage from "theme/images/Frohne-Artwork-Solutions_Logo_positiv.svg";

const HeaderStyle = styled.div`
  margin: 0;
  padding: 1rem 0;
  background-color: ${theme.colorPrimary};
`;

const Header = () => {
  return (
    <HeaderStyle>
      <div className="row">
        <Logo src={LogoImage} alt="Frohne Druck Logo" />
      </div>
    </HeaderStyle>
  );
};

export default Header;
