import { css } from "styled-components";

const Utilities = css`
  .util-paragraph-reduced {
    font-size: 1.6rem;
    line-height: 3rem;
    margin-bottom: 1rem !important;
  }

  .util-text-center {
    text-align: center !important;
  }

  .util-margin-top-small {
    margin-top: 1.5rem !important;
  }
  .util-margin-top-medium {
    margin-top: 4rem !important;
  }
  .util-margin-top-large {
    margin-top: 6rem !important;
  }
  .util-margin-top-huge {
    margin-top: 10rem !important;
  }

  .util-margin-bottom-small {
    margin-bottom: 1.5rem !important;
  }
  .util-margin-bottom-medium {
    margin-bottom: 4rem !important;
  }
  .util-margin-bottom-large {
    margin-bottom: 6rem !important;
  }
  .util-margin-bottom-huge {
    margin-bottom: 10rem !important;
  }
`;

export default Utilities;
