import React from "react";
import styled from "styled-components";

import profile from "profile";
import theme from "theme/theme";

import Link from "components/atoms/Link";

const FooterBarStyle = styled.div`
  margin: 0;
  padding: 1rem 0;
  background-color: ${theme.colorPrimaryDark};

  a {
    padding-right: 2rem;
  }
`;

const FooterBar = () => {
  const renderNavigation = () =>
    profile.footerNavi.map((link, i) => (
      <Link
        key={i}
        to={link.path}
        color={theme.colorGrey1}
        background={theme.colorSecondaryDark}
      >
        {link.title}
      </Link>
    ));
  return (
    <FooterBarStyle>
      <div className="row">{renderNavigation()}</div>
    </FooterBarStyle>
  );
};

export default FooterBar;
