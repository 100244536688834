import React from "react";
import styled from "styled-components";

import { respondTo } from "theme/utils/_respondTo";
import theme from "theme/theme";

import FooterBlock from "components/molecules/FooterBlock";
import BlockAddress from "components/molecules/BlockAddress";
import BlockNewsletter from "components/molecules/BlockNewsletter";
import BlockUSP from "components/molecules/BlockUSP";

const FooterInfoStyle = styled.div`
  margin: 0;
  padding: 4rem 0;
  background-color: ${theme.colorPrimary};

  .footer-grid {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 4rem;
  }

  ${respondTo.tport`
  .footer-grid {
    display: block;
  }
  `}
`;

const FooterInfo = (props) => {
  return (
    <FooterInfoStyle>
      <div className="row footer-grid">
        <FooterBlock>
          <BlockUSP />
        </FooterBlock>
        <FooterBlock>
          <BlockAddress />
        </FooterBlock>
        <FooterBlock>
          <BlockNewsletter />
        </FooterBlock>
      </div>
    </FooterInfoStyle>
  );
};

export default FooterInfo;
