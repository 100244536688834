import React from "react";
import styled from "styled-components";

import { respondTo } from "theme/utils/_respondTo";

const ContentRowStyle = styled.div`
  max-width: 70vw;
  height: 100%;
  margin: 0 auto;

  ${respondTo.desktop`
      max-width: 75vw;
    `}

  ${respondTo.tland`
    max-width: 80vw;
  `}

  ${respondTo.tport`
    max-width: 85vw;
  `}

  ${respondTo.phone`
    width: 100%;
    max-width: 90vw;
  `}
`;

const ContentRow = (props) => {
  return <ContentRowStyle>{props.children}</ContentRowStyle>;
};

export default ContentRow;
