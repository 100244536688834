import React from "react";
import styled from "styled-components";

import Image from "components/atoms/Image";
import Link from "components/atoms/Link";

const LogoStyle = styled.div`
  width: 200px;
  height: auto;
  z-index: 1000;
  position: relative;
`;

const Logo = (props) => {
  return (
    <LogoStyle>
      <Link to="/">
        <Image src={props.src} alt={props.alt} />
      </Link>
    </LogoStyle>
  );
};

export default Logo;
