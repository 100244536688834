import PropTypes from "prop-types";
import styled from "styled-components";

import theme from "theme/theme";

const HorizontalRule = styled.hr`
  border: 1px solid ${(props) => props.color};
  border-width: 0 0 1px;
  width: ${(props) => (props.width ? props.width : 100)}%;
`;

HorizontalRule.propTypes = {
  color: PropTypes.string,
  reverse: PropTypes.bool,
  width: PropTypes.string,
};

HorizontalRule.defaultProps = {
  color: theme.colorSecondary,
};

export default HorizontalRule;
