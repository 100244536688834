import React from "react";
import { Switch, Route } from "react-router-dom";

import Layout from "./hoc/Layout";

import HomePage from "./pages/HomePage";
import ImprintPage from "./pages/ImprintPage";
import PrivacyPage from "./pages/PrivacyPage";

const Router = () => {
  return (
    <Switch>
      <Layout>
        <Route component={ImprintPage} path="/impressum" />
        <Route component={PrivacyPage} path="/datenschutz" />
        <Route component={HomePage} exact path="/" />
      </Layout>
    </Switch>
  );
};

export default Router;
