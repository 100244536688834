import React from "react";
import styled from "styled-components";

import Heading from "components/atoms/Heading";
import HorizontalRule from "components/atoms/HorizontalRule";

const ContentTitleStyle = styled.div`
  padding: 0 0 4rem;
`;

const ContentTitle = (props) => {
  return (
    <ContentTitleStyle>
      <Heading level={2} color={props.color}>
        {props.title}
      </Heading>
      <HorizontalRule width="60" color={props.color} />
    </ContentTitleStyle>
  );
};

export default ContentTitle;
