// import gql from "graphql-tag";
import { gql } from "apollo-boost";

export const LANDINGPAGE = gql`
  query Landingpage($id: Int!) {
    landingpage(id: $id) {
      id
      title
      claim
      usp
      image {
        uri
        alt
      }
      contents {
        id
        ... on ValuePropositionItem {
          id
          title
          contents {
            id
            title
            icon
            description
          }
        }
        ... on FeatureItem {
          id
          title
          contents {
            id
            title
            icon
            description
          }
        }
        ... on FaqItem {
          id
          title
          contents {
            id
            question
            answer
          }
        }
        ... on LicenseItem {
          id
          title
          contents {
            id
            title
            color
            features
          }
        }
        ... on ParagraphsItem {
          id
          text
        }
        ... on ImageItem {
          id
          url
          alt
        }
        ... on CodeBlockItem {
          id
          title
          code
        }
        ... on CtaItem {
          id
          title
          description
          button_icon
          button_text
          button_link
        }
        ... on ExternalLink {
          id
          title
          description
          link_title
          link_url
        }
        ... on VideoItem {
          id
          title
          description
          link
        }
      }
    }
  }
`;

export const PAGE = gql`
  query Page($id: Int!) {
    page(id: $id) {
      id
      title
      body
    }
  }
`;
